@import '../../styles/abstracts/variables';

.loader {
  position: relative;
  height: 100%;
  left: 0;
  right: 0;

  &.fullScreen {
    position: absolute;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .loaderChildren {
    opacity: 0.2;
    pointer-events: none;
  }

  .loading {
    width: 100%;
    height: 100%;
    max-width: 38px;
    max-height: 38px;
    animation: circle_loading 1.9s linear infinite;

    div {
      width: 100%;
      height: 100%;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .loaderIcon {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $colorTextPrimary;
    font-size: 30px;
  }

  .marsLoader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 104/38;
    width: calc(104px * 3);
    max-width: 60dvw;

    &.fullPageAnimation {
      width: calc(104px * 5);
      max-width: 80dvw;
    }

    div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .shine {
    position: absolute;
    filter: blur(10px);
    opacity: 0.8;
    animation: shining 1s linear infinite alternate;

    &.fullPageAnimation {
      opacity: 0.8;
      animation: shining 1.5s linear infinite alternate;
    }
  }

  @keyframes circle_loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes shining {
    0% {
      filter: blur(0px);
    }
    100% {
      filter: blur(10px);
    }
  }
}
