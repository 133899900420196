$fontRobotoCondensed: 'Roboto Condensed', sans-serif;
$fontRoboto: 'Roboto', sans-serif;
$fontDruk: 'DrukLCG', sans-serif;
$fontDrukWide: 'DrukWideLCG', sans-serif;
$fontInter: 'Inter', sans-serif;

$backgroundColor: #383838;
$backgroundColorCommon: #262321;
$backgroundColorEpic: #041f17;
$backgroundColorRare: #23052b;
$backgroundColorUnique: #482b00;
$backgroundColorExclusive: #3c3301;
$backgroundColorTooltip: #322c22;
$backgroundPopUp: #23201e;

$fontColorWhite: #ffffff;
$fontColorBlack: #001f16;

$colorTextPrimary: #ffe9da;
$colorTextPrimaryAlpha: rgba(255, 233, 218, 0.6);
$colorTextPrimaryLessVisible: rgba(255, 233, 218, 0.3);
$colorTextPrimaryTransparent: rgba(255, 233, 218, 0.1);
$colorTextSecondary: #fab952;
$colorInfoText: #b7a69b;
$colorBackgroundPrimary: #141311;
$colorBackgroundPrimaryDarker: #181313;
$colorBackgroundPrimaryDark: #0f0f0f;
$colorSelectorMinusBorder: rgba(255, 219, 194, 0.5);
$colorSelectorMinusBackground: rgba(255, 228, 210, 0.06);
$colorRed: #ff3232;
$colorRedDark: #cf2437;
$colorNotFoundText: #020202;

$colorOrange: #ff5c00;
$colorBlazeOrange: #ff5c01;
$colorYellowOrange: #ffb342;
$colorBlack: #000000;
$colorIcon: #969696;
$colorPopoverContent: #cbcbcb;
$colorTableHeader: #969696;
$colorTableBorder: #333333;
$colorTableBodyBorder: #424242;
$colorGreyBorder: #424242;
$colorScrollbar: #6b6b6b;
$colorScrollbarTrack: #272729;
$colorWhite: #ffffff;
$colorHeader: #ffefd7;
$colorCommon: #888888;
$colorRare: #d941ff;
$colorEpic: #01ffb3;
$colorUnique: #fab952;
$colorExclusive: #ffd700;
$colorFilterBackground: #413630;
$colorStatBackground: #3f372a;
$colorStatActiveBarBackground: #ff8a01;
$colorProductivityScore: #0b0403;
$colorDescriptionGradient: #0b0a06;
$colorPendingOrderBackground: #514b46;
$colorDisabledPrimaryButton: #969696;
$backgroundDisabledPrimaryButton: #170d05;
$borderDisabledPrimaryButton: #803d00;

.centeredIcon {
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.fadeInAnimation {
  animation: fade-in 0.2s linear forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
